.KhHeader {
    padding: 0;

    border-bottom: 1px solid var(--hr-color-container-default);
}

.KhHeader .YandexHeader-Base {
    box-sizing: border-box;
    max-width: var(--content-max-width);
    padding-right: var(--hr-padding-l);
    padding-left: var(--hr-padding-l);
}

.KhHeader .PageHeader-User {
    margin-right: 0;
}

@media (max-width: 768px) {
    .KhHeader .YandexHeader-Base {
        padding-right: var(--hr-padding-m);
        padding-left: var(--hr-padding-m);
    }
}
