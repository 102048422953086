.StreamScreen-Controls {
    display: grid;
    align-items: center;
    grid-column-gap: 16px;

    grid-template-columns: repeat(5, min-content);
}

.StreamScreen-BackLink {
    margin-left: -8px;
}

.StreamScreen-PlayerTypeContainer {
    min-width: 240px;
}

.StreamScreen-StreamLanguageSwitcherContainer {
    min-width: 170px;
}

@media screen and (max-width: 767px) {
    .StreamScreen-Controls {
        grid-auto-flow: row dense;
        grid-auto-rows: min-content;
        grid-row-gap: 8px;
    }

    .StreamScreen-ChatLink .Icon_type_comment {
        width: 24px;

        background-size: 24px;
    }

    .StreamScreen-ChatLink .Button2-Text {
        display: none;
    }
}

@media screen and (max-width: 520px) {
    .StreamScreen-PlayerTypeContainer {
        grid-row: 2;
    }
}
