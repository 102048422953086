.BugFrame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 482px;
    min-height: 100px;
    padding: 16px;

    transition: height .3s;
}

.BugFrame-Iframe {
    width: 100%;

    transition: .3s height ease;
}

@media screen and (max-width: 540px) {
    .BugFrame {
        width: 280px;
    }
}

@media screen and (max-width: 360px) {
    .BugFrame {
        width: 260px;
    }
}
