.StreamScreen_loading .StreamScreen-Content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.StreamScreen_loading .StreamScreen-BackLink {
    width: 100%;
}

.StreamScreen_loading .StreamScreen-Spin {
    margin: 0 auto;
}
