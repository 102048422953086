.CallFormTitle-Title {
    display: flex;
    align-items: center;

    max-width: 560px;
    height: 32px;

    line-height: 32px;
}

.CallFormTitle-Text {
    overflow: hidden;

    font-size: 24px;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.CallFormTitle-BackLink {
    height: auto;
    margin-left: -8px;
}

.CallFormTitle-BackLink .Button2 {
    position: relative;
    left: -20px;
}

@media screen and (max-width: 767px) {
    .CallFormTitle-BackLink .Button2 {
        left: 0;

        font-size: 12px;
    }
}
