.Stub {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 344px;

    text-align: center;
}

.Stub-Image {
    width: 100%;
    max-width: 200px;
}

.Stub-Image img {
    width: 100%;
}

.Stub-Title {
    margin-top: 28px;

    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
}

.Stub-Description {
    margin-top: 8px;

    font-size: 13px;
    line-height: 20px;

    color: #7f8285;
}

.Stub-Button {
    margin-top: 12px;
}
