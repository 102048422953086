.ParticipantsList_isCollapsible .ParticipantsList-CollapseArrow {
    display: inline-block;

    margin-left: 8px;

    filter: brightness(.5);

    transform: rotate(-180deg);
}

.ParticipantsList_isCollapsible .ParticipantsList-CollapseArrow .Icon {
    height: auto;
}

.ParticipantsList_isCollapsible.ParticipantsList_isCollapsed .ParticipantsList-Content {
    display: none;
}

.ParticipantsList_isCollapsible.ParticipantsList_isCollapsed .ParticipantsList-CollapseArrow {
    transform: rotate(0);
}
