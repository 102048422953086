.StreamPoster-Duration .Duration {
    display: inline-block;
}

.StreamPoster-Duration .Duration::before {
    content: unset;
}

.StreamPoster-Duration .StreamPoster-StartTime {
    display: inline-block;

    font-size: 13px;

    color: #dddee4;
}

@media screen and (max-width: 767px) {
    .StreamPoster-Duration .StreamPoster-StartTime {
        font-size: 11px;
    }

    .StreamPoster-Duration .Duration {
        font-size: 11px;
    }
}
