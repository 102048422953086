.StreamPosterPreview {
    --stream-poster-margin-edge: calc(16px * var(--coefficient));
    --stream-poster-margin-bottom: calc(var(--stream-poster-margin-edge) * 2);
    --stream-poster-height: calc(256px * var(--coefficient));
    --stream-poster-width: calc(960px * var(--coefficient));
    --stream-poster-title-font-size: calc(20px * var(--coefficient));
    --stream-poster-title-line-height: calc(28px * var(--coefficient));
    --stream-poster-title-mobile-font-size: calc(18px * var(--coefficient));
    --stream-poster-title-max-width: calc(300px * var(--coefficient));
    --stream-poster-title-text-max-width: calc(100% - 32px * var(--coefficient));
    --stream-poster-description-margin-top: calc(16px * var(--coefficient));
    --stream-poster-description-font-size: calc(13px * var(--coefficient));
    --stream-poster-description-line-height: calc(18px * var(--coefficient));
    --stream-poster-description-max-width: calc(630px * var(--coefficient));
    display: flex;
    flex-wrap: wrap;

    margin: 0 calc(var(--stream-poster-margin-edge) * -1);
}

.StreamPosterPreview .StreamPoster {
    height: var(--stream-poster-height);
}

.StreamPosterPreview .StreamPoster-Actions {
    display: none;
}

.StreamPosterPreview .StreamPoster-PosterBackground {
    background-size: var(--stream-poster-width) var(--stream-poster-height);
}

.StreamPosterPreview .StreamPoster-Title {
    max-width: var(--stream-poster-title-max-width);

    font-size: var(--stream-poster-title-font-size);
}

.StreamPosterPreview .StreamPoster-Title .StreamPoster-TitleText {
    max-width: var(--stream-poster-title-text-max-width);

    line-height: var(--stream-poster-title-line-height);
}

.StreamPosterPreview .StreamPoster-Description {
    max-width: var(--stream-poster-description-max-width);
    margin-top: var(--stream-poster-description-margin-top);

    font-size: var(--stream-poster-description-font-size);
    line-height: var(--stream-poster-description-line-height);
}
