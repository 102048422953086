.TemplateFormScreen-Header .TemplateFormScreen-TitleBackLink {
    max-width: 560px;
}

.TemplateFormScreen-Header .TemplateFormScreen-TitleBackLink .Button2 {
    position: relative;
    left: -40px;

    max-width: 100%;

    font-size: 24px;
    font-weight: bold;

    color: #000;
}

.TemplateFormScreen-Header .TemplateFormScreen-TitleBackLink .Button2 .Button2-Icon {
    opacity: 1;
}

.TemplateFormScreen-Header .TemplateFormScreen-TitleBackLink .Button2 .Button2-Text {
    overflow: hidden;

    max-width: 100%;
    margin-left: 40px;

    text-overflow: ellipsis;

    opacity: 1;
}

.TemplateFormScreen-Header .TemplateFormScreen-BackLink {
    height: auto;
    margin-left: -8px;

    line-height: 36px;
}

.TemplateFormScreen-Header .TemplateFormScreen-BackLink .Button2 {
    font-size: 12px;
}

.TemplateFormScreen-Header .TemplateFormScreen-Title {
    overflow: hidden;

    font-size: 24px;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
}
