.CallFormTitle_edit .CallFormTitle-EditButton {
    flex-shrink: 0;

    margin-left: 4px;
}

.CallFormTitle_edit .CallFormTitle-EditButton,
.CallFormTitle_edit .CallFormTitle-EditButton .Icon_type_edit {
    height: 24px;
}

.CallFormTitle_edit .CallFormTitle-ActionButton {
    margin-left: 8px;
}
