.StreamsList {
    padding-bottom: 10px;
}

.StreamsList:not(:last-child) {
    border-bottom: 2px solid #ededed;
}

.StreamsList:not(:first-child) {
    margin-top: 40px;
}

.StreamsList-Title {
    margin-bottom: 30px;

    font-size: 20px;
    line-height: 24px;

    color: #4c4c4c;
}

.StreamsList-NumberStreams {
    margin-left: 8px;

    color: #b2b2b2;
}

.StreamsList-StreamPosters {
    display: flex;
    flex-wrap: wrap;

    margin: 0 -15px;
}

.StreamsList-StreamPosters .StreamPoster {
    box-sizing: border-box;
    margin: 0 15px 30px;
}

.StreamsList .StreamPoster_size_s {
    width: calc(100% * 1 / 3 - 30px);
}

.StreamsList .StreamPoster_size_m {
    width: calc(100% * 2 / 3 - 30px);
}

.StreamsList-ShowAll {
    margin: 20px 0;

    font-size: 13px;
    line-height: 18px;
    cursor: pointer;
    text-align: center;

    color: #026cd6;
}

.StreamsList-ShowAll::before {
    margin-right: 12px;

    content: url('assets/before-pagination.svg');
}

.StreamsList-ShowAll::after {
    margin-left: 12px;

    content: url('assets/after-pagination.svg');
}

@media screen and (max-width: 767px) {
    .StreamsList .StreamPoster {
        width: 100%;
    }
}
