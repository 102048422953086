.TableCell_type_callTime .FormatTime-Time {
    white-space: nowrap;

    color: #777;
}

.TableCell-Time_empty {
    margin: auto;
}

.TableCell_type_callTime .Icon {
    display: none;
}

@media screen and (max-width: 767px) {
    .TableCell_type_callTime {
        align-items: center;

        padding-bottom: 6px;

        font-size: 13px;

        color: #777;
    }

    .TableCell-Time_empty {
        margin: 0;
    }

    .TableCell_type_callTime .Icon {
        display: inline-block;

        margin-right: 6px;
    }
}
