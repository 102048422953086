.Table {
    box-sizing: border-box;

    border-radius: 5px;
    background: #fff;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .05);
}

.Table-ContentHeader {
    display: grid;

    padding: 16px 24px 10px;

    border-bottom: 1px solid #e1e2e7;
    grid-column-gap: 24px;
}

.Table-RowTitle {
    font-size: 13px;
    line-height: 20px;

    color: #7f8285;
}

.Table-ColumnTitle {
    font-size: 13px;
    line-height: 20px;

    color: #7f8285;
}

.Table-Row {
    display: grid;

    padding: 0 24px;

    font-size: 15px;
    line-height: 20px;
    vertical-align: top;

    border-bottom: 1px solid #eeeff5;
    grid-column-gap: 24px;
}

.Table-Row:nth-child(2n) {
    background: #fcfcfc;
}

.Table-Cell {
    display: flex;
    align-items: center;
}

.Table-Pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 64px;
}

.Table-Pagination .Button2 {
    height: 100%;
}

.Table-ColumnTitle_align_right,
.Table-Cell_align_right {
    justify-self: end;
}

.Table-EmptyText {
    padding: 20px 0;

    font-size: 13px;
    text-align: center;

    color: #7f8285;
}

@media screen and (max-width: 767px) {
    .Table-Row {
        padding: 0 16px;
    }

    .Table-Row:nth-child(2n) {
        background-color: #fff;
    }
}
