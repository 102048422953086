.CreateCallScreen {
    display: flex;
    justify-content: center;

    padding-bottom: 64px;
}

.CreateCallScreen-Container {
    display: flex;

    width: 100%;
    max-width: 960px;
    padding: 42px 16px 0 48px;
}

@media screen and (max-width: 767px) {
    .CreateCallScreen-Content {
        width: 100%;
    }

    .CreateCallScreen-Container {
        box-sizing: border-box;
        padding: 10px 16px 0 20px;
    }
}
