.ManagementPage-MessagesWrapper {
    display: flex;
    overflow: hidden;
    flex: 1;
    justify-content: center;
    align-items: flex-end;

    width: 100%;
    margin-top: var(--hr-space-xl);
}

.ManagementPage-Messages {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;

    min-height: 100%;
}

.ManagementPage-ButtonStopManagement {
    width: calc(100% - 2 * var(--hr-space-xl));
}
