.Table_type_activeCalls .Table-Header {
    padding: 24px 24px 12px;
}

.Table_type_activeCalls .Table-Header_admin {
    display: flex;
    justify-content: space-between;
}

.Table_type_activeCalls .Table-Title {
    margin: 0;
}

.Table_type_activeCalls .Table-Row {
    height: 68px;

    line-height: 22px;
    cursor: pointer;

    background-color: #fff;
    grid-column-gap: 20px;
}

.Table_type_activeCalls .Table-Row_actionsNumber_2 {
    grid-template-columns: 320px minmax(0, 1fr) 76px;
}

.Table_type_activeCalls .Table-Row_actionsNumber_3 {
    grid-template-columns: 320px minmax(0, 1fr) 120px;
}

.Table_type_activeCalls .Table-SwitcherWrapper {
    display: flex;
    align-items: center;
}

.Table_type_activeCalls .Table-SwitcherControl {
    display: flex;

    margin-left: 16px;
}

@media screen and (max-width: 767px) {
    .Table_type_activeCalls .Table-Header {
        padding: 16px 16px 8px;
    }

    .Table_type_activeCalls .Table-Row {
        display: block;

        height: auto;
        padding: 16px 16px 24px;
    }

    .Table_type_activeCalls .Table-Row:nth-child(2n) {
        background-color: #fff;
    }
}
