.Page {
    display: flex;
    flex-direction: column;

    min-height: 100vh;
}

.Page-Content {
    position: relative;
    z-index: 0;

    flex-grow: 1;

    padding-bottom: 32px;
}

.Page_theme_grey .Page-Content {
    background-color: #f9f9f9;
}
