.icon {
    vertical-align: middle;
}

.icon_theme_pseudo {
    position: relative;
}

.icon_theme_pseudo::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;

    margin: auto;

    content: '';

    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

.icon_theme_pseudo.icon_size_m {
    min-width: 16px;
}

.icon_theme_pseudo.icon_size_s {
    min-width: 14px;
}

.icon_theme_pseudo.icon_size_xs {
    min-width: 12px;
}

.icon_theme_pseudo.icon_size_m::before {
    width: 16px;
    height: 16px;
}

.icon_theme_pseudo.icon_size_s::before {
    width: 12px;
    height: 12px;
}

.icon_theme_pseudo.icon_size_xs::before {
    width: 8px;
    height: 8px;
}

.icon_type_cross::before {
    background-image: url('./assets/cross.svg');
}
