.CreateCallForm-Participants {
    margin-top: 40px;
}

.CreateCallForm-ParticipantsTitle {
    font-size: 18px;
    font-weight: bold;
}

.CreateCallForm-Participants .ParticipantsList {
    margin-top: 24px;
}
