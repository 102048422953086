.Participant,
.Participant-LeftSide,
.Participant-RightSide {
    display: flex;
    align-items: center;
}

.Participant,
.Participant-LeftSide {
    min-width: 0;
}

.Participant {
    justify-content: space-between;

    padding: 8px 0;
}

.Participant-MetaInfo {
    display: flex;
    flex-direction: column;

    min-width: 0;
    margin-left: 12px;
}

.Participant-Name {
    display: inline-block;
    overflow: hidden;

    width: 100%;

    white-space: nowrap;
    text-overflow: ellipsis;
}

.Participant-Subtitle {
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;

    color: #999;
}

.Participant-Textinput {
    width: 100%;
    min-width: 240px;
}
