.StreamPoster {
    position: relative;

    overflow: hidden;

    box-sizing: border-box;
    height: 256px;
    margin-top: 0;
    margin-right: var(--stream-poster-margin-edge);
    margin-bottom: var(--stream-poster-margin-bottom);
    margin-left: var(--stream-poster-margin-edge);
    padding: 24px 24px 20px;

    cursor: pointer;

    border-radius: 8px;
}

.StreamPoster-Container {
    position: relative;
    z-index: 2;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;
}

.StreamPoster-Header {
    display: flex;
    align-items: center;
}

.StreamPoster-Content {
    margin-top: auto;
}
