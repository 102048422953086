.Duration-Select {
    display: inline-block;

    margin-left: 8px;
}

.Duration-Select .Select2 {
    height: 24px;
}

.Duration-Select .Button2 {
    height: 24px;

    line-height: 24px;
}

.Duration-Remaining {
    margin-left: 12px;

    white-space: nowrap;
}
