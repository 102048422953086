.ParticipantsList-Header .Button2-Text {
    margin-left: 0;
}

.ParticipantsList-Title {
    display: inline-block;

    font-weight: bold;
}

.ParticipantsList-ElementsAmount {
    display: inline-block;

    margin-left: 4px;

    font-weight: bold;

    color: #999;
}

.ParticipantsList-Content {
    margin-top: 16px;
}

.ParticipantsList-Header[disabled]::before {
    background-color: transparent;
}

.ParticipantsList-Header + .ParticipantsList-Content {
    margin-top: 0;
}

.ParticipantsList-Group {
    padding: 0;

    font-size: 13px;
}

.ParticipantsList-GroupTitle {
    font-weight: bold;

    color: #555;
}

.ParticipantsList_listHidden .ParticipantsList-Header,
.ParticipantsList_listHidden .ParticipantsList-Content {
    display: none;
}
