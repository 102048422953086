.ShareLinkPopup {
    display: flex;
    flex-direction: column;

    max-width: 300px;
    padding: 16px;

    font-size: 13px;
}

.ShareLinkPopup .CopyText {
    align-self: flex-start;
}

.ShareLinkPopup-Title {
    margin-bottom: 16px;

    font-size: 15px;
}

.ShareLinkPopup-Link {
    overflow: hidden;

    white-space: nowrap;
    text-decoration: none;
    text-overflow: ellipsis;
}

.ShareLinkPopup-Copy {
    margin-top: 16px;

    cursor: pointer;

    color: #555;
}

.ShareLinkPopup-Copy::before {
    display: inline-block;

    width: 12px;
    height: 12px;
    margin-right: 8px;

    content: '';
    vertical-align: middle;

    background-image: url('./assets/share.svg');
    background-repeat: no-repeat;
}
