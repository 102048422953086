:root {
    --app-height: 100%;
}

.Clicker {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100vh;
    height: var(--app-height);
}

.Clicker-ContentWrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: var(--hr-color-page-background);
}

.Clicker-ContentWrapper_platform_desktop {
    width: 400px;
    max-height: 850px;

    border-radius: 32px;
}

@media screen and (max-height: 850px) {
    .Clicker-ContentWrapper_platform_desktop {
        max-height: 100%;
    }
}

.Clicker-ContentWrapper_platform_mobile {
    width: 100%;
    height: 100%;
}

.StartPage-Id,
.ManagementPage-Id {
    display: flex;
    align-items: center;

    margin: var(--hr-space-xl);

    font: var(--hr-typography-font-body-s-all-params);

    color: var(--hr-color-table-text-secondary);
}

.StartPage-IdBadge,
.ManagementPage-IdBadge {
    margin-right: var(--hr-space-xs);
}

.StartPage-Buttons,
.ManagementPage-Buttons,
.InstructionPage-Buttons {
    display: flex;

    width: calc(100% - 2 * var(--hr-space-xl));
    margin: var(--hr-space-xl) var(--hr-space-xl) var(--hr-space-2-xl);
}

.StartPage-ButtonInstruction,
.ManagementPage-ButtonPrev,
.InstructionPage-ButtonSkip {
    margin-right: 12px;
}

.StartPage-ButtonManage,
.StartPage-ButtonInstruction,
.ManagementPage-ButtonNext,
.ManagementPage-ButtonPrev,
.InstructionPage-ButtonSkip,
.InstructionPage-ButtonNext {
    width: calc(50% - 12px / 2);
}

/* Переопределение дизайн системы, убрать после обновления */
.StartPage-ButtonManage,
.StartPage-ButtonInstruction,
.ManagementPage-ButtonNext,
.ManagementPage-ButtonPrev,
.ManagementPage-ButtonStopManagement,
.InstructionPage-ButtonSkip,
.InstructionPage-ButtonNext {
    height: 56px;

    font: var(--hr-typography-font-body-s-all-params);
    font-weight: var(--hr-typography-font-weight-medium);
}

/* Переопределение дизайн системы, убрать после обновления */
.StartPage-ButtonManage::before,
.StartPage-ButtonInstruction::before,
.ManagementPage-ButtonNext::before,
.ManagementPage-ButtonPrev::before,
.ManagementPage-ButtonStopManagement::before,
.InstructionPage-ButtonSkip::before,
.InstructionPage-ButtonNext::before {
    border-radius: var(--hr-radius-m);
}

/* Переопределение дизайн системы, убрать после обновления */
.StartPage-ButtonInstruction::before,
.ManagementPage-ButtonPrev::before,
.InstructionPage-ButtonSkip::before,
.StartPage-ButtonInstruction:not([aria-disabled='true']):hover::before,
.ManagementPage-ButtonPrev:not([aria-disabled='true']):hover::before,
.InstructionPage-ButtonSkip:not([aria-disabled='true']):hover::before {
    background-color: var(--hr-color-surface-elevation-0);
}
