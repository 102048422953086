.Button2.Button2_tone_redLight::before {
    border-color: transparent;
    background-color: #ef7777;
}

.Button2.Button2_tone_redLight:hover::before {
    background-color: #e46c6c;
}

.Button2.Button2_tone_redLight .Button2-Text {
    color: #fff;
}
