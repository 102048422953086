.CreateCallScreen-MobileTemplates {
    margin: 12px -16px 0 -20px;
    padding: 0 16px 0 20px;
}

.CreateCallScreen-MobileTemplates_templateApplied .CreateCallScreen-AppliedTemplateIndicator {
    position: relative;
}

.CreateCallScreen-MobileTemplates_templateApplied .CreateCallScreen-AppliedTemplateIndicator::before {
    position: absolute;
    top: 6px;
    left: -11px;

    display: inline-block;

    width: 6px;
    height: 6px;

    content: '';

    border-radius: 50%;
    background-color: #ff0202;
}

.CreateCallScreen-MobileTemplates_visible {
    background-color: #f7f7f7;
}

.CreateCallScreen-MobileTemplates .CreateCallScreen-TemplatesSwitcher .Button2-Text {
    margin-left: 0;
}

.CreateCallScreen-MobileTemplatesList {
    display: none;

    margin-left: -20px;
}

.CreateCallScreen-MobileTemplates_visible .CreateCallScreen-MobileTemplatesList:not(.TemplateList_loading) {
    display: block;
}

.CreateCallScreen-MobileTemplates_visible .CreateCallScreen-MobileTemplatesList.TemplateList_loading {
    display: flex;
    justify-content: center;
    align-items: center;
}

.CreateCallScreen-MobileTemplatesList .TemplateList-Delimiter {
    margin-left: 20px;
}

.CreateCallScreen-MobileTemplatesList .Template {
    margin-right: -16px;
    padding: 16px;
}
