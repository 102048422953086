.Bubble_type_counter .Bubble-Content::before {
    font-size: 10px;
    content: '+';
}

.Bubble_type_counter .Bubble-Content {
    position: absolute;
    top: 50%;
    left: 50%;

    line-height: 23px;
    letter-spacing: -.2px;

    transform: translate(-50%, -50%);
}
