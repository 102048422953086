.TableCell_type_activeCallActions .TableCell-Button:not(:first-child) {
    margin-left: 12px;
}

@media screen and (max-width: 767px) {
    .TableCell_type_activeCallActions {
        flex-direction: column;
    }

    .TableCell_type_activeCallActions .TableCell-Button:not(:first-child) {
        margin: 16px 0 0;
    }
}
