.TableCell_type_eventCallActions {
    justify-content: flex-end;
}

.TableCell_type_eventCallActions .TableCell-Button {
    max-width: 32px;
}

.TableCell_type_eventCallActions .TableCell-Button:not(:first-child) {
    margin-left: 12px;
}

.TableCell_type_eventCallActions .Icon_type_zoom {
    background-size: 30px;
}

.TableCell_type_eventCallActions .TableCell-Button_type_telemost .Button2-Text {
    display: none;
}

.TableCell_type_eventCallActions .Icon_type_telemost {
    background-size: 28px;
}

@media screen and (max-width: 767px) {
    .TableCell_type_eventCallActions {
        flex-direction: column;
    }

    .TableCell_type_eventCallActions .Icon_type_zoomRect {
        background-size: 20px;
    }

    .TableCell_type_eventCallActions .Icon_type_telemost {
        background-size: 20px;
    }

    .TableCell_type_eventCallActions .TableCell-Button {
        max-width: none;
    }

    .TableCell_type_eventCallActions .TableCell-Button:not(:first-child) {
        margin: 16px 0 0;
    }
}
