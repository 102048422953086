.StreamScreen-Title {
    display: flex;
    align-items: center;

    padding-right: 16px;
}

.StreamScreen-Title .StreamScreen-Text {
    font-size: 24px;
}

.StreamScreen-Title .StreamScreen-ViewersCounter {
    flex-shrink: 0;

    margin-left: 16px;
    padding: 5px 10px;

    border: 1px solid #ccc;
    border-radius: 2px;
}

.StreamScreen-Title .StreamScreen-ViewersCounter .ViewersCounter-Count {
    color: #555;
}

@media screen and (max-width: 767px) {
    .StreamScreen-Title .StreamScreen-Text {
        overflow: hidden;

        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
