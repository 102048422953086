.Yabble {
    display: inline-block;

    height: 28px;
    padding: 3px;
}

.Yabble-Wrap {
    position: relative;

    display: flex;
    overflow: hidden;
    align-items: center;

    padding-right: 11px;

    border-radius: 28px;
    background: #ededed;
}

.Yabble-Title {
    font-size: 13px;
}

.Yabble-Wrap:hover {
    cursor: default;

    background: #dcdcdc;
}

.Yabble-Wrap .ParticipantAvatar {
    height: 28px;
    margin-right: 11px;
}

.Yabble-HoverButton.Button2 {
    position: absolute;
    right: -40px;

    width: 20px;

    text-align: left;

    transition: right .25s ease;
}

.Yabble-HoverButton:hover {
    cursor: pointer;
}

.Yabble-Wrap:hover .Yabble-HoverButton {
    right: 8px;

    background: #dcdcdc;
    box-shadow: 0 0 5px 7px rgba(220, 220, 220, 1);
}
