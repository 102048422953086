.CreateCallForm-UserCallControl {
    margin-top: 52px;
}

.CreateCallForm-InviteCallLink {
    margin-top: 42px;
}

.CreateCallForm-Participants {
    margin-top: 42px;
}

.CreateCallForm-ParticipantsTitle {
    font-size: 18px;
    font-weight: bold;
}

.CreateCallForm-Participants .ParticipantsList {
    margin-top: 24px;
}

.CreateCallForm-Record {
    display: flex;

    margin-top: 24px;
}

.CreateCallForm-Stream {
    display: flex;

    margin-top: 32px;
}

.CreateCallForm-RecordLabel,
.CreateCallForm-StreamLabel {
    flex-shrink: 0;

    width: 180px;
}

.CreateCallForm-RecordLabelTitle,
.CreateCallForm-StreamLabelTitle {
    font-size: 15px;
    font-weight: bold;
}

.CreateCallForm-RecordLabelSubTitle,
.CreateCallForm-StreamLabelSubTitle {
    margin-top: 4px;

    font-size: 11px;

    color: #999;
}

.CreateCallForm-StreamControl,
.CreateCallForm-RecordControl {
    margin-left: 54px;
}

.CreateCallForm-StreamNotification {
    margin-top: 4px;

    font-size: 11px;

    color: #e05252;
}

.CreateCallForm-ControlButtons {
    margin-top: 36px;
}

.CreateCallForm-ControlButton:not(:last-child) {
    margin-right: 16px;
}

@media screen and (max-width: 767px) {
    .CreateCallForm-NameLabel {
        margin-top: 0;
    }

    .CreateCallForm-Name {
        display: block;

        margin-top: 20px;
    }

    .CreateCallForm-NameField {
        max-width: unset;
        margin-top: 8px;
        margin-left: 0;
    }

    .CreateCallForm-ControlButton {
        width: 100%;
    }

    .CreateCallForm-ControlButton:not(:last-child) {
        margin-right: 0;
        margin-bottom: 16px;
    }
}
