.StreamPoster-Description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;

    max-width: 630px;
    margin-top: 16px;

    font-size: 13px;
    line-height: 18px;
    cursor: initial;
    text-overflow: ellipsis;

    color: #dddee4;
}
