.MethodSelector-OptionContentIcon_type_cisco {
    background-size: 26px;
}

.MethodSelector-OptionContentIcon_type_jabber {
    background-size: 17px;
}

.MethodSelector-OptionContent_type_cisco {
    display: inline-flex;
    flex-direction: column;
}
