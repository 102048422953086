.Settings {
    width: 230px;
    padding: 20px;
}

.Settings-DefaultConnectionType {
    margin: 0;
}

.Settings-Controls {
    margin-top: 30px;
}

.Settings-ButtonCancel.Button2 {
    margin-left: 12px;
}

.Settings-ButtonCross.Button2 {
    display: none;
}

.Settings-Select {
    margin-top: 12px;
}

@media screen and (max-width: 767px) {
    .Settings {
        box-sizing: border-box;
        width: 100%;
    }

    .Settings-ButtonCancel.Button2 {
        display: none;
    }

    .Settings-ButtonCross.Button2 {
        position: absolute;
        top: 10px;
        right: 20px;

        display: inline-block;
    }
}
