.PermanentLinkScreen-Content {
    max-width: 1748px;
    margin: 0 auto;
    padding: 0 20px;
}

.PermanentLinkScreen-Title {
    display: block;

    text-align: center;
}

.PermanentLinkScreen-AdditionalInformation {
    max-width: max-content;
    margin: 10px auto 0;

    font-size: 15px;
    font-weight: normal;
}
