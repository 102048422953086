.StartPage-TitleWrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.StartPage-Title {
    margin-top: var(--hr-space-m);
    margin-bottom: var(--hr-space-2-xs);

    font: var(--hr-typography-font-title-m-all-params);

    color: var(--hr-color-text-primary);
}

.StartPage-Subtitle {
    width: 80%;

    font: var(--hr-typography-font-body-m-all-params);
    text-align: center;

    color: var(--hr-color-text-secondary);
}

.StartPage-PinInput {
    max-width: 320px;
    margin: var(--hr-space-m) 0;
}

.StartPage-ButtonInit {
    height: 56px;

    font: var(--hr-typography-font-body-s-all-params);
    font-weight: var(--hr-typography-font-weight-medium);
}
