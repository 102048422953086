.TemplateList {
    min-height: 200px;
}

.TemplateList-Delimiter {
    width: 100px;
    height: 1px;
    margin-left: 24px;

    border-radius: 2px;
    background: #e0e0e0;
}

.TemplateList-Delimiter:last-child {
    display: none;
}
