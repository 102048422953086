.KhPage-Content {
    box-sizing: border-box;
}

.KhPage-Content .Grid {
    box-sizing: border-box;
    max-width: var(--content-max-width);
    padding-right: var(--hr-padding-l);
    padding-left: var(--hr-padding-l);
}

.KhPage-Content .Grid-Col {
    box-sizing: border-box;
    min-height: calc(100vh - 61px); /* 61px - высота шапки + ширина ее бордера */
}

@media (max-width: 768px) {
    .KhPage-Content .Grid {
        padding-right: var(--hr-padding-m);
        padding-left: var(--hr-padding-m);
    }

    .KhPage-Content .Grid-Col {
        padding-bottom: 32px;
    }
}
