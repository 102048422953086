.TemplateFormScreen-Stream {
    margin-top: 32px;
}

.TemplateFormScreen-Stream_streamSettingsVisible {
    margin: 16px -16px -16px;
    padding: 16px;

    background-color: #ededed;
}

.TemplateFormScreen-Stream .TemplateFormScreen-StreamField {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.TemplateFormScreen-Stream .TemplateFormScreen-StreamPermanentLinks {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.TemplateFormScreen-Stream .TemplateFormScreen-StreamContent {
    display: flex;
    align-items: flex-start;
}

.TemplateFormScreen-Stream .TemplateFormScreen-StreamLabel {
    max-width: 150px;
}

.TemplateFormScreen-Stream .TemplateFormScreen-StreamDescriptionLabelTitle,
.TemplateFormScreen-Stream .TemplateFormScreen-StreamLabelTitle,
.TemplateFormScreen-Stream .TemplateFormScreen-StreamPictureLabelTitle {
    font-size: 15px;
    font-weight: bold;
}

.TemplateFormScreen-Stream .TemplateFormScreen-StreamDescriptionLabelSubTitle,
.TemplateFormScreen-Stream .TemplateFormScreen-StreamPictureLabelSubTitle,
.TemplateFormScreen-Stream .TemplateFormScreen-StreamLabelSubTitle {
    margin-top: 4px;

    font-size: 11px;

    color: #999;
}

.TemplateFormScreen-Stream .TemplateFormScreen-StreamControl {
    margin-left: 54px;
}

.TemplateFormScreen-Stream .TemplateFormScreen-StreamPicture {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    margin-top: 16px;
}

.TemplateFormScreen-Stream .TemplateFormScreen-StreamPictureLabel {
    max-width: 160px;
    margin-top: 16px;
    margin-right: 16px;

    white-space: normal;
}

.TemplateFormScreen-Stream .TemplateFormScreen-StreamPictureField {
    display: flex;
    flex-grow: 1;

    min-width: 200px;
    max-width: 350px;
    margin-top: 16px;
}

.TemplateFormScreen-Stream .TemplateFormScreen-StreamPictureAttach,
.TemplateFormScreen-Stream .TemplateFormScreen-StreamPictureAttach .Button2 {
    display: flex;

    width: 100%;
}

.TemplateFormScreen-Stream .TemplateFormScreen-StreamPictureAttach .Button2-Text {
    overflow: hidden;

    width: 100%;

    text-overflow: ellipsis;
}

.TemplateFormScreen-Stream .TemplateFormScreen-StreamPosterPreview {
    /* Коэффициент уменьшения размеров обложки (ширина области предпросмотра / ширина области списка трансляций) */
    --coefficient: 0.58;
    margin-top: 32px;
}

.TemplateFormScreen-Stream .TemplateFormScreen-StreamPictureMobileAlert {
    margin-top: 8px;

    font-size: 11px;

    color: #e05252;
}

.TemplateFormScreen-Stream .TemplateFormScreen-StreamDescription {
    margin-top: 32px;
}

.TemplateFormScreen-Stream .TemplateFormScreen-StreamDescriptionField {
    margin-top: 16px;
}

@media screen and (max-width: 767px) {
    .TemplateFormScreen-Stream .TemplateFormScreen-StreamField {
        flex-direction: column;
    }

    .TemplateFormScreen-Stream_streamSettingsVisible {
        margin-left: -20px;
        padding-left: 20px;
    }

    .TemplateFormScreen-Stream .TemplateFormScreen-StreamPermanentLinks {
        align-items: flex-start;
    }
}
