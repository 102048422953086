.Participant_mobile {
    display: block;

    box-sizing: border-box;
    max-width: 100vw;
    margin: 0 -16px;
    padding: 8px 16px;
}

.Participant_mobile .Participant-Main,
.Participant_mobile .Participant-LeftSide,
.Participant_mobile .Participant-RightSide {
    display: flex;
    align-items: center;
}

.Participant_mobile .Participant-LeftSide {
    flex-grow: 1;

    min-width: 0;
}

.Participant_mobile .Participant-Main {
    justify-content: space-between;
}

.Participant_mobile .Participant-MetaInfo {
    display: flex;
    flex-direction: column;

    max-width: calc(100% - 40px); /* 40px - ширина иконки + отсуп текста от иконки Participant */
    margin-left: 12px;
}

.Participant_mobile .Participant-Subtitle {
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;

    color: #999;
}

.Participant_mobile .Participant-Parameters {
    display: none;

    width: 100%;
}

.Participant_mobile.Participant_isParametersVisible {
    background-color: #f7f7f7;
}

.Participant_mobile.Participant_isParametersVisible .Participant-Parameters {
    display: block;
}
