.StreamScreen-RateInformerTitle_state_error {
    display: flex;
    align-items: center;

    font-size: 16px;
    font-weight: 500;
}

.StreamScreen-RateInformerTitle_state_error .StreamScreen-RateInformerErrorIcon svg {
    vertical-align: middle;

    fill: #fa1919;
}

.StreamScreen-RateInformerTitle_state_error .StreamScreen-RateInformerErrorText {
    margin-left: 8px;
}
