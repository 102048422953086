@font-face {
    font-family: 'YS Text';
    src: url('./YS Text-Regular.woff2') format('woff2'), url('./YS Text-Regular.woff') format('woff');
    /* stylelint-disable-next-line font-weight-notation */
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'YS Text';
    src: url('./YS Text-Medium.woff2') format('woff2'), url('./YS Text-Medium.woff') format('woff');
    /* stylelint-disable-next-line font-weight-notation */
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'YS Text';
    src: url('./YS Text-Bold.woff2') format('woff2'), url('./YS Text-Bold.woff') format('woff');
    /* stylelint-disable-next-line font-weight-notation */
    font-weight: 700;
    font-style: normal;
}
