.Participant_controls .Participant-ControlButton {
    margin-right: 8px;
}

@media screen and (max-width: 767px) {
    .Participant_controls .Participant-ControlButton {
        margin-right: 0;
        margin-bottom: 12px;
    }
}
