.TableCell_type_callName {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.TableCell_type_callName .TableCell-CallName {
    overflow: hidden;

    max-width: 100%;

    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.TableCell_type_callName .TableCell-EventCallParameters {
    display: inline-block;

    white-space: nowrap;
}

.TableCell-CallAttribute_type_recorded {
    margin-left: 16px;

    opacity: .3;
}

.TableCell-CallAttribute_type_camera .Icon_type_camera {
    background-size: 26px;
}

.TableCell-CallAttribute_type_camera {
    display: inline-block;

    margin-left: 16px;

    cursor: pointer;

    opacity: .3;
}

.TableCell-CallAttribute_type_camera:hover {
    opacity: 1;
}

.TableCell_type_callName .TableCell-CallIDValue {
    color: #777;
}

.TableCell_type_callName .TableCell-CallID .StaffName {
    display: none;
}

@media screen and (max-width: 767px) {
    .TableCell_type_callName .TableCell-Name {
        font-size: 15px;
        font-weight: bold;
    }

    .TableCell_type_callName .TableCell-CallID {
        font-size: 13px;
    }

    .TableCell_type_callName .TableCell-CallIDValue {
        display: inline-block;

        margin-right: 10px;
    }

    .TableCell_type_callName .TableCell-CallID .StaffName {
        display: inline-block;

        color: inherit;
    }
}
