.YandexBug_isHidden {
    display: none;
}

.YandexBug-ModalContent {
    margin: 8px;
}

.YandexBug-CloseButton {
    float: right;
}

.YandexBug-Insect {
    position: fixed;
    z-index: 1000;
    bottom: 0;

    display: inline-block;

    width: 36px;
    height: 36px;

    cursor: pointer;

    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 3px;
    outline: none;
    background: url('./assets/bug.svg') no-repeat center;
    background-color: #fff;
    background-size: 72%;

    transition: .1s ease-out;
}
