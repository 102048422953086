.TemplateFormScreen-GoToCalendar {
    display: inline-flex;
    align-items: center;

    max-width: 100%;
    margin-top: 6px;

    font-size: 13px;
    text-decoration: none;

    color: #555;
}

.TemplateFormScreen-GoToCalendar .TemplateFormScreen-EventName {
    overflow: hidden;

    margin-left: 4px;

    white-space: nowrap;
    text-overflow: ellipsis;
}

.TemplateFormScreen-GoToCalendar .Icon_type_yandexCalendar {
    width: 20px;

    background-size: 20px;
}
