.CallTemplate {
    position: relative;
}

.CallTemplate-title {
    margin: 0 0 12px;
    padding-right: 6px;

    word-wrap: break-word;
}

.CallTemplate-buttons {
    display: grid;
    grid-template-columns: fit-content(100%) fit-content(100%) fit-content(100%);
    grid-column-gap: 16px;

    margin-top: 1em;
}

.CallTemplate-label {
    display: block;

    margin: 0.5em 0;

    font-weight: bold;
}

.CallTemplate-participants {
    display: flex;
    overflow-y: auto;
    flex-wrap: wrap;

    max-height: 126px;
}

.CallTemplate-CopyPermanentLink.CopyText {
    display: flex;

    margin-top: 12px;

    white-space: nowrap;
}

.CallTemplate-CopyPermanentLink.CopyText_reverse {
    justify-content: flex-end;
}

.CallTemplate-Delete {
    position: absolute;
    top: -8px;
    right: -20px;
}
