.PermanentLinkScreen-GoToCalendar {
    display: inline-flex;
    align-items: center;

    max-width: 100%;
}

.PermanentLinkScreen-GoToCalendar .Icon_type_yandexCalendar {
    width: 50px;
    margin-left: 6px;

    background-size: 34px;
}

.PermanentLinkScreen-EventName {
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}
