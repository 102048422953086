.Bubble {
    position: relative;

    display: inline-block;
    overflow: hidden;

    box-sizing: border-box;
    min-width: 24px;
    height: 24px;
    padding: 0 11px;

    font-size: 13px;
    line-height: 24px;
    vertical-align: middle;

    border-radius: 24px;
    background-color: #f1f1f1;
}

.Bubble-Avatar {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 24px;

    transform: translate(-50%, -50%);
}
