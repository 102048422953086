.Page_authRequired .Page-AuthReplacerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100vh;
}

.Page_authRequired .Page-AuthReplacerWrapper .ErrorBox {
    width: 100%;
    max-width: 500px;
}
