.CallFormTitle_type_event .CallFormTitle-Icon {
    flex-shrink: 0;

    width: 24px;
    height: 24px;

    background-size: 24px;
}

.CallFormTitle_type_event .CallFormTitle-Link {
    overflow: hidden;

    margin-left: 8px;

    font-size: 24px;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
}
