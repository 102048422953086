.InstructionPage-Indicator {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: var(--hr-space-xs);

    margin: var(--hr-space-m) 0 var(--hr-space-xl);
}

.InstructionPage-IndicatorItem {
    width: var(--hr-space-xs);
    height: var(--hr-space-xs);

    border-radius: 50%;
    background-color: var(--hr-color-control-default);
}

.InstructionPage-IndicatorItem_active {
    width: var(--hr-space-xl);

    border-radius: 10px;
    background-color: #015fbf;
}

.InstructionPage-Title {
    width: calc(100% - 2 * var(--hr-space-xl));
    margin: var(--hr-space-xl) 0 var(--hr-space-m);

    font: var(--hr-typography-font-title-m-all-params);
}

.InstructionPage-Info {
    display: flex;
    flex: 1;
    flex-direction: column;

    width: calc(100% - 2 * var(--hr-space-xl));

    font: var(--hr-typography-font-paragraph-m-all-params);
}

.InstructionPage-InfoItem {
    display: flex;

    margin-bottom: 12px;
}

.InstructionPage-InfoEmoji {
    margin-right: var(--hr-space-xs);
}

.InstructionPage-Image {
    width: calc(100% - 2 * var(--hr-space-xl));

    aspect-ratio: 10 / 8;

    border-radius: 32px;
    background-size: cover;
}

.InstructionPage-Image_type_howItWorks {
    background-image: url('../../ClickerZoom.assets/goose_image1.jpeg');
}

.InstructionPage-Image_type_please {
    background-image: url('../../ClickerZoom.assets/goose_image2.jpeg');
}

.InstructionPage-Image_type_whatCouldGoWrong {
    background-image: url('../../ClickerZoom.assets/goose_image3.jpeg');
}
