.KhFooter {
    width: 100%;

    font: var(--hr-typography-font-body-s-all-params);

    color: var(--hr-color-text-secondary);
    border-top: 1px solid var(--hr-color-container-default);
}

.KhFooter-Content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    box-sizing: border-box;
    max-width: var(--content-max-width);
    height: 60px;
    margin: 0 auto;
    padding-right: var(--hr-space-2-xl);
    padding-left: var(--hr-space-2-xl);
}

.KhFooter-Section_type_copyright {
    font-weight: var(--hr-typography-fontWeight-medium);
}

.KhFooter-Link {
    color: var(--hr-color-text-secondary);
}

.KhFooter-Link:not(:first-child) {
    margin-left: var(--hr-space-xl);
}

@media screen and (max-width: 768px) {
    .KhFooter-Content {
        flex-direction: column;

        height: unset;
        padding-top: var(--hr-space-2-xl);
        padding-bottom: var(--hr-space-2-xl);
    }

    .KhFooter-Link {
        display: block;

        text-align: center;
    }

    .KhFooter-Link:not(:first-child) {
        margin-top: var(--hr-space-xl);
        margin-left: unset;
    }

    .KhFooter-Section_type_copyright {
        margin-top: var(--hr-space-xl);
    }
}
