.HomeScreen {
    display: flex;
    justify-content: center;

    width: 100%;
    padding-top: 1rem;
    padding-bottom: 2rem;
}

.HomeScreen-Head {
    display: flex;
    align-items: center;
}

.HomeScreen-Content {
    display: flex;

    width: 80%;
}

.HomeScreen-Main {
    width: 65%;
}

.HomeScreen-Secondary {
    flex-shrink: 0;

    width: 35%;
    margin-left: 50px;
}

.HomeScreen-CalendarEvents.Unit,
.HomeScreen-ActiveCalls.Unit {
    padding: 0;
}

.HomeScreen-CreateCallButton.Button2 {
    margin-left: auto;
}

.HomeScreen-CreateCallButton.Button2 .Button2-Text {
    margin-left: 0;
}

@media screen and (max-width: 1220px) {
    .HomeScreen-Content {
        flex-direction: column;
    }

    .HomeScreen-Main {
        width: 100%;
    }

    .HomeScreen-Secondary {
        width: 100%;
        margin-top: 24px;
        margin-left: 0;
    }
}

@media screen and (max-width: 767px) {
    .HomeScreen {
        padding-top: 0;
        padding-bottom: 0;
    }

    .HomeScreen-Content {
        width: 100%;
    }

    .HomeScreen-Head {
        padding: 0 16px;
    }
}
