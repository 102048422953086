.Button2_responsive.Button2_pin_circle-circle .Button2-Text {
    display: none;
}

.Button2.Button2_responsive .Button2-Icon + .Button2-Text {
    margin-left: 0;
}

@media screen and (max-width: 767px) {
    .Button2.Button2_responsive.Button2_pin_circle-circle::before {
        border-radius: 4px;
    }

    .Button2.Button2_responsive.Button2_pin_circle-circle .Button2-Text,
    .Button2.Button2_responsive.Button2_width_max .Button2-Text {
        display: inline-block;
    }

    .Button2.Button2_responsive .Button2-Icon {
        vertical-align: top;
    }
}
