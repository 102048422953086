.Table_type_history .Table-Header {
    padding: 24px 24px 16px;
}

.Table_type_history .Table-FiltersMenuButton {
    display: none;
}

.Table_type_history .Table-ContentHeader {
    grid-template-columns: 100px 240px 140px minmax(180px, 1fr) 124px;

    padding: 16px 16px 10px;
    grid-column-gap: 20px;
}

.Table_type_history .Table-Row {
    height: 64px;

    line-height: 22px;
}

.Table_type_history .Table-Row_actionsNumber_3 {
    grid-template-columns: 100px 240px 140px minmax(180px, 1fr) 124px;
}

.Table_type_history .Table-Row_actionsNumber_4 {
    grid-template-columns: 100px 240px 140px minmax(136px, 1fr) 168px;
}

@media screen and (max-width: 1024px) {
    .Table_type_history .Table-Header {
        padding: 24px 16px 16px;
    }

    .Table_type_history .Table-ContentHeader {
        grid-template-columns: 100px 200px 100px minmax(100px, 1fr) 124px;

        padding: 16px 16px 10px;
        grid-column-gap: 20px;
    }

    .Table_type_history .Table-Row {
        grid-column-gap: 20px;

        padding: 0 16px;
    }

    .Table_type_history .Table-Row_actionsNumber_3 {
        grid-template-columns: 100px 220px 120px minmax(100px, 1fr) 124px;
    }

    .Table_type_history .Table-Row_actionsNumber_4 {
        grid-template-columns: 100px 200px 100px minmax(100px, 1fr) 168px;
    }
}

@media screen and (max-width: 767px) {
    .Table_type_history .Table-Header {
        padding: 16px 16px 8px;
    }

    .Table_type_history .Table-Row {
        display: block;

        height: auto;
        padding: 16px 16px 24px;
    }

    .Table_type_history .Table-FiltersMenuButton {
        display: block;
    }

    .Table_type_history .Table-Filters {
        display: none;

        padding-top: 16px;
    }

    .Table_type_history .Table-Filters_filtersMenuOpen {
        display: block;
    }

    .Table_type_history .Table-FiltersRadioButton {
        width: 100%;
    }

    .Table_type_history .Table-FiltersRadio {
        width: 50%;
    }

    .Table_type_history .Table-ContentHeader {
        display: none;
    }

    .Table_type_history .Table-Cell_type_authorLogin {
        display: none;
    }
}
