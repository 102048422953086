.HistoryScreen {
    display: flex;
    justify-content: center;
}

.HistoryScreen-Main {
    box-sizing: border-box;
    width: 100%;
    max-width: 1024px;
    padding: 32px 8px;
}

.HistoryScreen-Title {
    margin: 0;

    font-size: 24px;
    line-height: 28px;
}

.HistoryScreen-Content {
    padding-top: 32px;
}

.HistoryScreen_isCallsHistoryEmpty .HistoryScreen-Header {
    display: none;
}

.HistoryScreen_isCallsHistoryEmpty .HistoryScreen-Content {
    display: flex;
    justify-content: center;

    padding-top: 120px;
}

@media screen and (max-width: 767px) {
    .HistoryScreen {
        background-color: #fff;
    }

    .HistoryScreen-Main {
        width: 100%;
        padding: 16px 0 0;
    }

    .HistoryScreen-Header {
        padding-left: 16px;
    }

    .HistoryScreen-Content {
        padding-top: 0;
    }
}
