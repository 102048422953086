.Duration {
    font-size: 13px;
    line-height: 24px;
}

.Duration::before {
    display: inline-block;

    width: 12px;
    height: 12px;
    margin-right: 4px;

    content: '';

    background-image: url('./assets/handset.svg');
    background-repeat: no-repeat;
}
