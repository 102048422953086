.Suggest {
    position: relative;
}

.Suggest .react-autosuggest__suggestion {
    list-style: none;
}

.Suggest .react-autosuggest__suggestions-list {
    position: absolute;
    z-index: 2;

    width: 100%;
    margin: 0;
    padding: 0;

    background: #fff;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
}

.Suggest .react-autosuggest__suggestion--highlighted {
    background: #f7f7f7;
}

.Suggest .react-autosuggest__input {
    position: relative;
    z-index: 3;

    box-sizing: border-box;
    width: 100%;
    height: 36px;
    padding: 0 8px;

    font-size: 15px;

    border: 2px solid #d9d9d9;
    border-radius: 4px;
    outline: none;
}

.Suggest .react-autosuggest__input--focused {
    padding: 0 8px;

    border: 2px solid #b3b3b3;
}

.Suggest .react-autosuggest__input::placeholder {
    opacity: 0.3;
    color: #000;
}
