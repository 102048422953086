.KhStreamScreen-Controls {
    display: flex;
    align-items: center;

    margin-left: var(--hr-space-xs);
}

.KhStreamScreen-Controls .Button2_size_m .Icon {
    width: var(--button-size-m-height);
}

.KhStreamScreen-Controls .KhStreamScreen-Control:not(:first-child) {
    margin-left: var(--hr-space-m);
}

.KhStreamScreen-Controls .KhStreamScreen-Settings {
    width: 320px;
    padding: 20px var(--hr-padding-m);

    border-color: transparent;
    border-radius: var(--hr-radius-m);
    background-color: var(--hr-color-base-white);
    box-shadow:
        0 16px 24px rgba(0, 38, 77, 0.06), /* stylelint-disable-line value-list-comma-space-after */
        0 2px 6px rgba(0, 38, 77, 0.04), /* stylelint-disable-line value-list-comma-space-after */
        0 0 1px rgba(0, 38, 77, 0.04), /* stylelint-disable-line value-list-comma-space-after */
        0 -2px 8px rgba(0, 38, 77, 0.04);
}

.KhStreamScreen-Controls .KhStreamScreen-Settings::before {
    background-color: transparent;
    box-shadow: none;
}

.KhStreamScreen-Controls .KhStreamScreen-SettingControl:not(:first-child) {
    margin-top: var(--hr-space-m);
}

.KhStreamScreen-Controls .KhStreamScreen-ViewersCounter {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    font: var(--hr-typography-font-body-s-all-params);

    color: var(--hr-color-text-secondary);
}

.KhStreamScreen-Controls .KhStreamScreen-ViewersCounterValue {
    margin-left: var(--hr-space-xs);
}
