.Yabble_fixed .Yabble-Wrap {
    padding-right: 6px;
}

.Yabble_fixed .Yabble-HoverButton {
    position: relative;
    right: unset;

    width: unset;
    height: 28px;
}

.Yabble_fixed .Yabble-HoverButton .Button2-Icon {
    height: 28px;
}

.Yabble_fixed .Yabble-Wrap:hover .Yabble-HoverButton {
    right: unset;

    box-shadow: unset;
}
