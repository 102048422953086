.StreamPoster-PosterBackground,
.StreamPoster-Background {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
}

.StreamPoster-Background {
    z-index: 1;

    background: linear-gradient(180deg, rgba(28, 29, 39, .56) 0%, rgba(28, 29, 39, .84) 100%);
}

.StreamPoster-PosterBackground {
    transition: transform .4s linear;
}

.StreamPoster:hover .StreamPoster-PosterBackground {
    transform: scale(1.05);
}
