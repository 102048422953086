.KhStreamScreen_loading .KhStreamScreen-Content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.KhStreamScreen_loading .KhStreamScreen-BackLink {
    width: 100%;
}

.KhStreamScreen_loading .KhStreamScreen-Spin {
    margin: 0 auto;
}
