.BubbleParticipants_popup {
    cursor: pointer;

    outline: none;
}

.BubbleParticipantsPopup {
    min-width: 250px;
    padding: 12px 12px 0;
}

.BubbleParticipantsPopup-Content {
    overflow-y: auto;

    max-height: 312px;
}

.BubbleParticipantsPopup-Header {
    margin-bottom: 12px;

    font-size: 15px;
    font-weight: bold;
    line-height: 20px;
}

.BubbleParticipantsPopup-Text_type_secondary {
    color: #999;
}

.BubbleParticipantsPopup-CloseButton {
    display: none;
}

.BubbleParticipantsPopup-Row_type_person .StaffName {
    cursor: pointer;
}

.BubbleParticipantsPopup-Row {
    display: flex;
    align-items: center;

    margin-bottom: 12px;

    font-size: 13px;
    line-height: 16px;
}

.BubbleParticipantsPopup-Avatar {
    width: 28px;
    margin-right: 12px;

    border-radius: 50%;
}

@media screen and (max-width: 767px) {
    .BubbleParticipantsPopup {
        position: fixed;
        z-index: 1001;
        top: 0;
        left: 0;

        width: 100vw;
        height: 100vh;
        padding: 12px 16px;

        background-color: #fff;
    }

    .Popup2.Popup2_view_default {
        border-radius: 0;
    }

    .BubbleParticipantsPopup-Content {
        max-height: 100%;
    }

    .BubbleParticipantsPopup-Header {
        margin-bottom: 16px;

        font-size: 18px;
        line-height: 24px;
    }

    .BubbleParticipantsPopup-CloseButton {
        position: absolute;
        top: 12px;
        right: 12px;

        display: block;

        width: 20px;
        height: 20px;

        border: none;
        background: url('assets/cross.svg') no-repeat;
        background-size: 20px 20px;
    }
}
