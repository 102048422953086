.Table_type_events .Table-Header {
    display: flex;
    align-items: center;

    padding: 24px 24px 12px;
}

.Table_type_events .Table-CalendarIcon {
    width: 27px;
    height: 26px;

    background-size: contain;
}

.Table_type_events .Table-Title {
    margin: 0 0 0 6px;
}

.Table_type_events .Table-Row {
    grid-template-columns: 90px minmax(206px, 1fr) minmax(100px, 1fr) 120px;

    height: 48px;

    line-height: 22px;

    background-color: #fff;
    grid-column-gap: 20px;
}

.Table_type_events .Table-Row .TableCell_type_callName {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

@media screen and (max-width: 767px) {
    .Table_type_events .Table-Header {
        padding: 16px 16px 8px;
    }

    .Table_type_events .Table-Row {
        display: block;

        height: auto;
        padding: 16px 16px 24px;
    }

    .Table_type_events .Table-Row:nth-child(2n) {
        background-color: #fff;
    }
}
