.ActiveCallScreen-Parameters {
    margin-top: 10px;

    font-size: 13px;
}

.ActiveCallScreen-Parameters .Duration {
    color: #555;
}

.ActiveCallScreen-Parameters .ActiveCallScreen-Row {
    margin-top: 12px;
}

/* stylelint-disable-next-line selector-max-universal */
.ActiveCallScreen-Parameters .ActiveCallScreen-Row > *:not(:last-child) {
    margin-right: 16px;
}

.ActiveCallScreen-Parameters .ActiveCallScreen-ButtonCalendarAwesome .Icon_type_calendarAwesome {
    width: 12px;
}

@media screen and (max-width: 767px) {
    .ActiveCallScreen-Parameters {
        flex-wrap: wrap;
        justify-content: space-between;

        max-width: 560px;
        margin-top: 0;
        padding: 10px 0 0;
    }

    .ActiveCallScreen-Parameters:not(.ActiveCallScreen-Parameters_isDetailsHidden) {
        margin-right: -16px;
        margin-left: -16px;
        padding: 10px 16px 16px;

        background-color: #f7f7f7;
    }

    /* stylelint-disable-next-line selector-max-universal */
    .ActiveCallScreen-Parameters .ActiveCallScreen-Row > *:not(:last-child) {
        margin-right: 0;
    }

    .ActiveCallScreen-Parameters .ActiveCallScreen-DetailsButton {
        margin-left: auto;
    }

    .ActiveCallScreen-Parameters .ActiveCallScreen-Details {
        margin-top: 16px;

        line-height: 26px;
    }

    .ActiveCallScreen-Parameters_isDetailsHidden .ActiveCallScreen-Details {
        display: none;
    }

    /* stylelint-disable-next-line selector-max-universal */
    .ActiveCallScreen-Parameters .ActiveCallScreen-Details > *:not(:last-child) {
        margin-right: 16px;
    }

    .ActiveCallScreen-Parameters .ActiveCallScreen-Row {
        display: flex;
        align-items: center;
    }
}
