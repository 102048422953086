.TemplateFormScreen {
    display: flex;
    justify-content: center;
}

.TemplateFormScreen-Container {
    width: 100%;
    max-width: 920px;
    padding: 42px 16px 0 48px;
}

.TemplateFormScreen-Content {
    max-width: 560px;
}

.TemplateFormScreen-Name,
.TemplateFormScreen-Event,
.TemplateFormScreen-Owners {
    display: flex;
    justify-content: space-between;

    margin-top: 24px;
}

.TemplateFormScreen-Owners {
    position: relative;
    z-index: 4;
}

.TemplateFormScreen-NameLabel,
.TemplateFormScreen-EventLabel,
.TemplateFormScreen-OwnersLabel {
    margin-top: 10px;

    font-size: 15px;
}

.TemplateFormScreen-NameField,
.TemplateFormScreen-EventField,
.TemplateFormScreen-OwnersField {
    width: 100%;
    max-width: 413px;
}

.TemplateFormScreen-NameField .Textinput-Box,
.TemplateFormScreen-EventField .Textinput-Box,
.TemplateFormScreen-OwnersField .react-autosuggest__input {
    border-radius: 4px;
}

.TemplateFormScreen-OwnersList {
    margin-top: 4px;
}

.TemplateFormScreen-Participants {
    margin-top: 40px;
}

.TemplateFormScreen-ParticipantsTitle {
    font-size: 18px;
    font-weight: bold;
    line-height: 17px;
}

.TemplateFormScreen-Participants .ParticipantsList {
    margin-top: 20px;
}

.TemplateFormScreen-Record {
    display: flex;

    margin-top: 32px;
}

.TemplateFormScreen-RecordLabel {
    max-width: 150px;
}

.TemplateFormScreen-RecordLabelTitle {
    font-size: 15px;
    font-weight: bold;
}

.TemplateFormScreen-RecordLabelSubTitle {
    margin-top: 4px;

    font-size: 11px;

    color: #999;
}

.TemplateFormScreen-RecordControl {
    margin-left: 54px;
}

.TemplateFormScreen-ControlButtons {
    margin-top: 36px;
}

.TemplateFormScreen-ControlButton:not(:last-child) {
    margin-right: 16px;
}

@media screen and (max-width: 767px) {
    .TemplateFormScreen-Container {
        padding: 10px 16px 0 20px;
    }

    .TemplateFormScreen-NameLabel,
    .TemplateFormScreen-EventLabel,
    .TemplateFormScreen-OwnersLabel {
        margin-top: 0;
    }

    .TemplateFormScreen-Name,
    .TemplateFormScreen-Event,
    .TemplateFormScreen-Owners {
        display: block;

        margin-top: 20px;
    }

    .TemplateFormScreen-NameField,
    .TemplateFormScreen-EventField,
    .TemplateFormScreen-OwnersField {
        max-width: unset;
        margin-top: 8px;
    }

    .TemplateFormScreen-CopyPermanentLink {
        margin-top: 16px;
    }

    .TemplateFormScreen-ControlButton {
        width: 100%;
    }

    .TemplateFormScreen-ControlButton:not(:last-child) {
        margin-right: 0;
        margin-bottom: 16px;
    }
}
