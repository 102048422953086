.StreamScreen-Content {
    max-width: 1748px;
    margin: 0 auto;
    padding: 0 20px;
}

.StreamScreen-Wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.StreamScreen-Sides {
    display: flex;

    margin-top: 32px;
}

.StreamScreen-Left {
    flex-grow: 1;

    min-width: 320px;
    max-width: 1280px;
}

.StreamScreen-Right {
    flex-grow: 1;

    min-width: 366px;
    max-width: 400px;
    padding-left: 28px;
}

.StreamScreen-Chat {
    border-top: 1px solid #e7eaf0;
}

@media screen and (max-width: 1024px) {
    .StreamScreen-Wrapper {
        flex-direction: column;
    }
}

@media screen and (max-width: 767px) {
    .StreamScreen {
        height: calc(100% - 50px);
        padding: 0;
    }

    .StreamScreen-BackLink {
        margin-left: 12px;
    }

    .StreamScreen-Wrapper {
        flex-direction: column;
        justify-content: flex-start;
    }

    .StreamScreen-Title {
        overflow: hidden;

        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .StreamScreen-Sides {
        flex-direction: column;

        height: calc(100% - 40px);
    }

    .StreamScreen-Left {
        flex-grow: 0;

        min-width: 320px;
        max-width: 768px;
        padding-left: 0;
    }

    .StreamScreen-Right {
        min-width: 320px;
        max-width: 768px;

        /* Явно задаем высоту родительскому элементу, чтобы срабатывало height: 100% у дочернего. Тк StreamScreen-Right
         * флекс-элемент (см. .StreamScreen), то height: 1px не окажет влияния на его итоговую высоту.
         */
        height: 1px;
        padding-right: 0;
        padding-left: 0;
    }
}
