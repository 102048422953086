.Footer {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 20px 24px 60px;

    font-size: 12px;

    border-top: 1px solid #dedddb;
}

.Footer-Content {
    display: flex;
    justify-content: space-between;

    width: 100%;
}
