.ActiveCallScreen-StreamInfo,
.ActiveCallScreen-StreamInfo_type_noStream {
    white-space: nowrap;

    color: #555;
}

.ActiveCallScreen-ShareStreamButton {
    margin-top: 16px;
}
