.SuggestItem {
    padding: 0 12px;

    cursor: pointer;
}

.SuggestItem-Title {
    font-size: 13px;

    color: #555;
}

.SuggestItem .Participant-LeftSide {
    max-width: 100%;
}

.SuggestItem .Participant-MetaInfo {
    overflow: hidden;
}

.SuggestItem .Participant-Title {
    overflow: hidden;

    font-size: 13px;
    line-height: 1;
}

.SuggestItem .Participant-Subtitle {
    font-size: 13px;
}
