.Table_type_streams .Table-ContentHeader {
    grid-template-columns: minmax(156px, 1fr) minmax(100px, 1fr) minmax(86px, 1fr) 50px minmax(220px, 1fr);
}

.Table_type_streams .Table-Row {
    grid-template-columns: minmax(156px, 1fr) minmax(100px, 1fr) minmax(86px, 1fr) 50px  minmax(220px, 1fr);
}

.Table_type_streams .Table-ActionButtons {
    width: 100%;
    padding: 12px 0;
}

.Table_type_streams .Duration {
    font-size: 15px;
}

.Table_type_streams .Table-ActionButtons .Button2_width_max {
    width: auto;
    margin-right: 12px;
}

.Table_type_streams .Table-ActionButtons .Table-CalendarAwesomeButton {
    display: inline-block;

    width: 32px;
}

.Table_type_streams .Table-ActionButtons .Table-TvButton {
    width: 32px;
}

.Table_type_streams .Table-TvButton .Button2-Text {
    display: block;

    margin: 0;
}

.ShareLinkPopup {
    display: flex;
    flex-direction: column;

    max-width: 300px;
    padding: 16px;

    font-size: 13px;
}

.ShareLinkPopup .CopyText {
    align-self: flex-start;
}

.ShareLinkPopup-Title {
    margin-bottom: 16px;

    font-size: 15px;
}

.ShareLinkPopup-Link {
    overflow: hidden;

    margin-bottom: 16px;

    white-space: nowrap;
    text-decoration: none;
    text-overflow: ellipsis;
}

.ShareLinkPopup-Copy {
    cursor: pointer;

    color: #555;
}

.ShareLinkPopup-Copy::before {
    display: inline-block;

    width: 12px;
    height: 12px;
    margin-right: 8px;

    content: '';
    vertical-align: middle;

    background-image: url('./assets/share.svg');
    background-repeat: no-repeat;
}

.Table_type_streams .Table-StartTime {
    display: none;
}

.Table_type_streams .Table-StreamName {
    overflow: hidden;

    max-width: 100%;

    white-space: nowrap;
    text-overflow: ellipsis;
}

@media screen and (max-width: 767px) {
    .Table_type_streams .Table-ContentHeader {
        display: none;
    }

    .Table_type_streams .Table-StartTime {
        display: inline-block;
    }

    .Table_type_streams .Table-StreamName {
        font-size: 17px;
        font-weight: bold;
    }

    .Table_type_streams .Table-Row {
        display: block;

        padding-top: 16px;
    }

    .Table_type_streams .Table-ActionButtons {
        padding: 0;
    }

    .Table_type_streams .Table-ActionButtons .Button2_width_max {
        width: 100%;
    }

    .Table_type_streams .Table-Observers {
        display: flex;
        align-items: center;
    }

    .Table_type_streams .Table-ActionButtons .Button2:not(:last-child) {
        margin-bottom: 12px;
    }

    .Table_type_streams .Table-Observers::before {
        display: inline-block;

        width: 12px;
        height: 12px;
        margin-right: 4px;

        content: '';
        vertical-align: middle;

        background-image: url('./assets/crowd.svg');
        background-repeat: no-repeat;
        background-size: 12px 12px;
    }

    .Table_type_streams .Table-Stats {
        display: inline-block;
    }

    .Table_type_streams .Table-Countdown {
        margin-right: 8px;
    }

    .Table_type_streams .Table-Cell {
        margin-bottom: 8px;
    }
}
