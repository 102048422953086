.KhStreamScreen-Content {
    display: grid;
    grid-template-rows: 1fr;

    box-sizing: border-box;
    min-height: calc(100vh - 61px); /* 61px - высота шапки + ширина ее бордера */
    padding-top: var(--hr-space-2-xl);
    padding-bottom: var(--hr-space-2-xl);
}

.KhStreamScreen-Sides {
    display: flex;
}

.KhStreamScreen-Left {
    flex-grow: 1;

    min-width: 320px;
    max-width: 1280px;
    height: min-content;
    padding: var(--hr-padding-l) var(--hr-padding-l) var(--hr-padding-s);

    border-radius: var(--hr-radius-xl);
    background: var(--hr-color-base-background);
}

.KhStreamScreen-Right {
    overflow: hidden;
    flex-grow: 1;

    box-sizing: border-box;
    width: 100%;
    max-width: 445px;
    min-height: 420px;
    max-height: calc(100vh - var(--header-content-height) - var(--hr-space-2-xl) - var(--hr-space-m));
    margin-left: var(--hr-space-xl);
    padding-top: var(--hr-padding-xs);

    border-radius: var(--hr-radius-xl);
    background: var(--hr-color-base-background);
    /* https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari */
    transform: translateZ(0);
}

.KhStreamScreen-Player {
    overflow: hidden;

    border-radius: var(--hr-radius-m);
    /* https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari */
    transform: translateZ(0);
}

.KhStreamScreen-Wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-top: var(--hr-space-xl);
}

.KhStreamScreen-Title {
    margin: 0;

    font: var(--hr-typography-font-title-l-all-params);
}

.KhStreamScreen-Description {
    max-width: 800px;
    margin-top: var(--hr-space-xl);

    font: var(--hr-typography-font-body-s-all-params);

    color: var(--hr-color-text-secondary);
}

.KhStreamScreen-Links {
    margin-top: var(--hr-space-m);

    font-weight: var(--hr-typography-font-weight-medium);
}

.KhStreamScreen-Links .Button2 {
    margin-left: calc(-1 * var(--hr-space-m));
}

.KhStreamScreen-Links .Button2:not(:last-child) {
    margin-right: var(--hr-space-s);
}

.KhStreamScreen-TroubleshootingTooltip {
    max-width: 300px;
}

.KhStreamScreen-TroubleshootingContent {
    padding-left: var(--hr-padding-xs);

    font: var(--hr-typography-font-body-s-all-params);
}

.KhStreamScreen-Solution::before {
    content: '– ';
}

.KhStreamScreen-Solution {
    position: relative;
}

.KhStreamScreen-Solution:not(:first-child) {
    margin-top: var(--hr-space-l);
}

@media screen and (max-width: 1300px) {
    .KhStreamScreen-Right {
        min-width: 350px;
    }
}

@media screen and (max-width: 1024px) {
    .KhStreamScreen-Content {
        padding-top: var(--hr-padding-m);
        padding-bottom: 0;
    }

    .KhStreamScreen-Title {
        font: var(--hr-typography-font-title-m-all-params);
    }

    .KhStreamScreen-Sides {
        display: grid;
        grid-template-rows: min-content 1fr;
    }

    .KhStreamScreen-Left {
        padding: var(--hr-padding-m) var(--hr-padding-m) var(--hr-padding-xs);
    }

    .KhStreamScreen-Right {
        min-width: 320px;
        max-width: none;
        min-height: none;
        max-height: none;
        margin-top: var(--hr-space-m);
        margin-left: 0;
    }

    .KhStreamScreen-Description {
        margin-top: var(--hr-space-m);
    }

    .KhStreamScreen-Links {
        margin-top: var(--hr-space-xs);
    }
}

@media screen and (max-width: 767px) {
    .KhStreamScreen-Left {
        padding: 0;
    }

    .KhStreamScreen-Wrapper {
        padding: 0 var(--hr-padding-m) var(--hr-padding-m);
    }

    .KhStreamScreen-Player {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
}
