.TextCollapse_collapse .TextCollapse-Text {
    overflow: hidden;
}

.TextCollapse_collapse.TextCollapse_collapsed .TextCollapse-Text {
    max-height: 51px; /* Дефолтное значение максимальной высоты, равное высоте трех строк */
}

.TextCollapse_collapse.TextCollapse_collapsed .TextCollapse-Text::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;

    height: 80%;

    content: '';
    pointer-events: none;

    background: linear-gradient(to top, #fff 0, rgba(255, 255, 255, .9) 5%, rgba(255, 255, 255, 0) 100%);
}

.TextCollapse_collapse .TextCollapse-ToggleLink {
    margin-top: 8px;

    font-size: 13px;
}
