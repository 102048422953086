.Yabble_fullClickable .Yabble-ClickableArea {
    position: absolute;

    width: 100%;
    height: 100%;

    border-radius: 28px;
}

.Yabble_fullClickable .Yabble-ClickableArea:hover {
    cursor: pointer;
}
