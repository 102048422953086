.CreateCallForm-Duration {
    margin-top: 12px;

    font-size: 13px;
    line-height: 24px;
}

.CreateCallForm-Duration .DurationSelector {
    display: inline-block;

    margin-left: 8px;
}

.CreateCallForm-Duration .DurationSelector .Select2 {
    height: 24px;
}

.CreateCallForm-Duration .DurationSelector .Button2 {
    height: 24px;

    line-height: 24px;
}
