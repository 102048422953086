.Button2.Button2_tone_green::before {
    border-color: transparent;
    background-color: #3db25b;
}

.Button2.Button2_tone_green:hover::before {
    background-color: #32a750;
}

.Button2.Button2_tone_green .Button2-Text {
    color: #fff;
}
