.StaffName {
    display: inline-block;

    cursor: default;
    white-space: nowrap;
}

.StaffName::first-letter {
    color: #f00;
}
