.KhAnnouncementScreen {
    height: 100%;
}

.KhAnnouncementScreen-Content {
    display: flex;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

.KhAnnouncementScreen-ErrorState_type_announcement .ErrorState-Title {
    order: 1;

    margin-top: var(--hr-space-xs);
    margin-bottom: 0;
}

.KhAnnouncementScreen-ErrorState_type_announcement .ErrorState-Description {
    margin-bottom: 0;
}

.KhAnnouncementScreen-ErrorState_type_announcement .ErrorState-Actions {
    order: 2;

    margin-top: var(--hr-space-xl);
}

.KhAnnouncementScreen-Title {
    display: block;

    margin-top: 0;

    text-align: center;
}

@media screen and (max-width: 768px) {
    .KhAnnouncementScreen-ErrorState_type_announcement .ErrorState-Content {
        padding-bottom: var(--hr-padding-l);
    }
}
