.Player {
    position: relative;

    overflow: hidden;

    width: 100%;
    height: 0;
    padding-top: 56.25%; /* обеспечивает aspect ratio 16:9 */
}

.Player-Inner {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
}
