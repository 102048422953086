.PermanentLinkScreen_loading {
    height: 100vh;
}

.PermanentLinkScreen-Spinner {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
}
