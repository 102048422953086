.ErrorBox {
    padding-bottom: 2rem;

    text-align: center;

    border: 4px solid var(--gray);
    background: #fff;
}

.ErrorBox-Message {
    margin: 0;

    font-weight: bold;
}
