.ActiveCallScreen_empty .ActiveCallScreen-Stub {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    min-height: 400px;
}

.ActiveCallScreen_empty .ActiveCallScreen-Stub .Stub_type_noConnection {
    width: 400px;
}
