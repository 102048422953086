.TableCell-Button_type_record {
    position: relative;
}

.TableCell-Button_type_record .TableCell-DeleteRecordButton::before {
    background-color: #fff;
}

.TableCell-Button_type_record .TableCell-DeleteRecordButton {
    position: absolute;
    top: -10px;
    right: -10px;
}

@media screen and (min-width: 767px) {
    .TableCell-Button_type_record .TableCell-DeleteRecordButton {
        width: 24px;
        height: 24px;
    }

    .TableCell-Button_type_record .TableCell-DeleteRecordButton .Button2-Icon {
        width: 24px;
        height: 24px;
    }
}

@media screen and (max-width: 767px) {
    .TableCell-Button_type_record .TableCell-DeleteRecordButton {
        position: relative;
        top: unset;
        right: unset;

        display: block;

        width: 100%;
        margin: 12px 0 0;
    }
}
