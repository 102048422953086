.Template {
    position: relative;

    padding: 0 16px 0 20px;

    border-left: 4px solid transparent;
    outline: none;
}

.Template:hover {
    cursor: pointer;
}

.Template:hover,
.Template:focus {
    background-color: #f5f5f5;
}

.Template_active {
    border-left-color: #ff0202;
}

.Template_disabled {
    pointer-events: none;

    opacity: .2;
}

.Template-Title {
    overflow: hidden;

    font-size: 15px;
    font-weight: bold;
    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Template-Participants {
    width: max-content;
    max-width: 100%;
    margin-top: 6px;
}

@media screen and (max-width: 767px) {
    .Template {
        box-sizing: border-box;
        width: 100vw;
    }
}
