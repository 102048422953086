.KhPage_error .KhPage-Error {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
}

.KhPage_error .Stub {
    max-width: 400px;
}
