.StreamPoster-Title {
    max-width: 300px;

    font-size: 20px;

    color: #fff;
}

.StreamPoster-Title .StreamPoster-TitleText {
    display: -webkit-inline-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;

    max-width: calc(100% - 32px);

    line-height: 28px;
    vertical-align: text-bottom;
    text-overflow: ellipsis;
    word-break: break-word;
}

.StreamPoster-Title .StreamPoster-TitleText:hover {
    cursor: pointer;
}

.StreamPoster-Title .StreamPoster-Hint {
    display: inline-block;

    margin-left: 4px;

    vertical-align: text-bottom;
}

@media screen and (max-width: 980px) {
    .StreamPoster-Title {
        font-size: 18px;
    }
}
