.MessageCard {
    display: flex;
    justify-content: space-between;
    align-items: center;

    box-sizing: border-box;
    width: calc(100% - 2 * var(--hr-space-xl));
    height: 68px;
    margin: var(--hr-space-2-xs) var(--hr-space-xl);
    padding: 0 var(--hr-padding-m);

    border-radius: var(--hr-radius-m);
    background-color: var(--hr-color-surface-elevation-0);
}

.MessageCard-Avatar {
    width: 40px;
    height: 40px;
    margin-right: var(--hr-space-m);
}

.MessageCard-Content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
}

.MessageCard-Message {
    font: var(--hr-typography-font-body-s-all-params);
}

.MessageCard-Time {
    font: var(--hr-typography-font-caption-m-all-params);

    color: var(--hr-color-text-secondary);
}

.MessageCard-Icons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 48px;
}

.MessageCard-Icon .Icon {
    width: var(--hr-space-m);
}

.MessageCard-Icon {
    color: var(--hr-color-spectre-orange-500);
}

.MessageCard-Icon_arrow {
    color: var(--hr-color-text-secondary);
}

.MessageCard-Icon_hasError {
    color: var(--hr-color-accent-negative-default);
}

.MessageCard-Icon_isProcessed {
    color: var(--hr-color-accent-positive-default);
}
