.Hint-Button.Button2_action::before {
    border: none;
    background-color: rgba(0, 0, 0, .3);
}

.Hint-Popup {
    width: 70%;
    max-width: 290px;
    padding: 8px 12px;

    font-size: 13px;
    line-height: 18px;
}
