.StreamPoster-Actions {
    margin-left: -6px;
}

.StreamPoster-Actions .StreamPoster-ButtonTv {
    width: 28px;
}

.StreamPoster-Actions .StreamPoster-ButtonTv .Button2-Text {
    margin: 0;

    font-size: 18px;
    line-height: 23px;
    vertical-align: middle;

    color: #dddee4;
}

.StreamPoster-Actions .StreamPoster-ButtonCalendar .Icon_type_calendarGray {
    background-size: 14px;
}

.StreamPoster-Actions .StreamPoster-ButtonShareLink .Icon_type_shareGray {
    background-size: 18px;
}

.StreamPoster-Actions .StreamPoster-ButtonComment {
    margin-left: 0;
}

.StreamPoster-Actions .StreamPoster-ButtonComment .Icon_type_commentGray {
    line-height: 27px;

    background-size: 17px;
}
