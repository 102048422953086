.CopyText {
    display: inline-flex;

    line-height: 20px;
    cursor: pointer;
}

.CopyText .Icon {
    margin: 0 4px;
}

.CopyText-Text {
    font-size: 13px;

    color: #555;
}
