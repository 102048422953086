.KhErrorState {
    overflow: hidden;

    width: 100%;
    max-width: 343px;

    background-color: var(--hr-color-base-background);
}

.KhErrorState .ErrorState-Image {
    height: 258px;
}

.KhErrorState .ErrorState-ImageContainer {
    border-radius: 0;
}

.KhErrorState .ErrorState-Content {
    display: flex;
    flex-direction: column;

    padding-top: var(--hr-space-2-xl);
}

.KhErrorState .ErrorState-Description {
    line-height: 24px;
}

@media screen and (max-width: 768px) {
    .KhErrorState {
        max-width: 280px;
    }

    .KhErrorState .ErrorState-Image {
        height: 215px;
    }

    .KhErrorState .ErrorState-Title {
        font: var(--hr-typography-font-title-s-all-params);
    }

    .KhErrorState .ErrorState-Description {
        font: var(--hr-typography-font-body-s-all-params);
    }

    .KhErrorState .ErrorState-Content {
        padding-bottom: var(--hr-padding-l);
    }
}
