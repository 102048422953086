:root {
    --content-max-width: 1748px;
}

.KhPage {
    display: flex;
    flex-direction: column;

    min-height: 100vh;

    font-family: var(--hr-typography-font-family-default);
    font-feature-settings: 'pnum' on, 'lnum' on;
}

.KhPage_contentTheme_gray {
    background-color: var(--hr-color-page-background);
}
