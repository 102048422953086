.CallTemplateList-Widget {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.CallTemplateList-Label h3 {
    margin: 0;
}

@media screen and (max-width: 767px) {
    .CallTemplateList-Title {
        padding-left: 16px;
    }
}
