.ActiveCallScreen {
    display: flex;
    justify-content: center;
}

.ActiveCallScreen-Content {
    display: flex;

    width: 100%;
    max-width: 920px;
    padding: 42px 16px 0 48px;
}

.ActiveCallScreen-Main {
    flex-grow: 1;
}

.ActiveCallScreen-Duration {
    display: flex;
    justify-content: space-between;
    align-items: center;

    max-width: 560px;
    margin-top: 32px;
}

.ActiveCallScreen-DurationLabel {
    font-size: 15px;
}

.ActiveCallScreen-DurationField {
    width: 100%;
    max-width: 420px;
    margin-left: 16px;
}

.ActiveCallScreen-Main .ActiveCallScreen-UserCallControl,
.ActiveCallScreen-Participants {
    margin-top: 52px;
}

.ActiveCallScreen-ParticipantsTitle {
    display: flex;
    align-items: center;

    max-width: 560px;
}

.ActiveCallScreen-CopyInviteLinkButton {
    margin-left: auto;
}

.ActiveCallScreen-CopyInviteLinkButton .Button2-Text {
    margin-right: 0;
}

.ActiveCallScreen-ParticipantsTitleText {
    font-size: 18px;
    font-weight: bold;
}

.ActiveCallScreen-ParticipantsContent {
    max-width: 588px;
    margin-top: 20px;
}

.ActiveCallScreen-AddParticipants,
.ActiveCallScreen-DisconnectedParticipants {
    margin-bottom: 16px;
}

.ActiveCallScreen-AddParticipants .Suggest {
    max-width: 560px;
}

.ActiveCallScreen-AddParticipants .ActiveCallScreen-AddParticipantsButtons,
.ActiveCallScreen-DisconnectedParticipants .ActiveCallScreen-DisconnectedParticipantsButtons {
    margin-top: 12px;
    padding-bottom: 24px;

    border-bottom: 1px solid #e5e5e5;
}

.ActiveCallScreen-AddParticipants .ActiveCallScreen-AddParticipantsButtons .Button2,
.ActiveCallScreen-DisconnectedParticipants .ActiveCallScreen-AddParticipantsButtons .Button2 {
    margin-right: 16px;
}

.ActiveCallScreen-Controls {
    margin-top: 60px;
}

@media screen and (max-width: 767px) {
    .ActiveCallScreen-Main {
        flex-grow: 0;
    }

    .ActiveCallScreen-Content {
        flex-direction: column;

        box-sizing: border-box;
        padding: 0 16px;
    }

    .ActiveCallScreen-Controls .Button2,
    .ActiveCallScreen-AddParticipantsButtons .Button2,
    .ActiveCallScreen-DisconnectedParticipantsButtons .Button2 {
        width: 100%;
    }

    .ActiveCallScreen-AddParticipantsButtons .Button2:not(:last-child) {
        margin-bottom: 16px;
    }
}
