.ActiveCallScreen-Header .ActiveCallScreen-Title {
    overflow: hidden;

    max-width: 560px;

    font-size: 24px;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ActiveCallScreen-Header .ActiveCallScreen-BackLink .Button2 {
    position: relative;
    left: -32px;
}

.ActiveCallScreen-Header .ActiveCallScreen-BackLink .Button2 .Button2-Text {
    margin-left: 40px;
}

.ActiveCallScreen-Header .ActiveCallScreen-BackLink {
    height: auto;
    margin-left: -8px;

    line-height: 36px;
}

@media screen and (max-width: 767px) {
    .ActiveCallScreen-Header .ActiveCallScreen-BackLink .Button2 {
        left: 0;
    }

    .ActiveCallScreen-Header .ActiveCallScreen-BackLink .Button2 .Button2-Text {
        margin-left: 29px;

        font-size: 12px;
    }
}
