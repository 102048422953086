.BubbleParticipants {
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    align-items: center;

    width: 100%;
    height: 28px;

    font-size: 13px;
}

.BubbleParticipants-RoomBubble {
    height: 24px;
    margin-right: 4px;
    padding: 2px 0;
}

.BubbleParticipants-CounterBubble,
.BubbleParticipants-PersonBubble,
.BubbleParticipants-ArbitraryBubble,
.BubbleParticipants-CmsBubble {
    position: relative;

    height: 24px;
    margin-left: -10px;

    border: 2px solid #fff;
    border-radius: 24px;
}

.BubbleParticipants-CounterBubble_isFirst,
.BubbleParticipants-PersonBubble_isFirst,
.BubbleParticipants-ArbitraryBubble_isFirst,
.BubbleParticipants-CmsBubble_isFirst {
    margin-left: 0;
}
