.TableCell_type_callParticipants .TableCell-Participants {
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}

.ActiveCalls .TableCell_type_callParticipants .BubbleParticipants {
    width: max-content;
    max-width: 100%;
}

@media screen and (max-width: 767px) {
    .TableCell_type_callParticipants {
        padding: 12px 0;

        font-size: 13px;
    }
}
