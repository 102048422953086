.UserCallControl {
    max-width: 560px;

    font-size: 13px;
}

.UserCallControl-TitleText {
    font-size: 18px;
    font-weight: bold;
}

.UserCallControl-Content {
    display: flex;
    align-items: center;

    margin-top: 8px;
}

.UserCallControl-Participant:not(.Participant_mobile) {
    flex-grow: 1;
}

.UserCallControl-Participant:not(.Participant_mobile) + .UserCallControl-Button {
    margin-left: 16px;
}

@media screen and (max-width: 767px) {
    .UserCallControl-Content {
        flex-direction: column;
        align-items: unset;
    }

    .UserCallControl-Participant.Participant_isParametersVisible + .UserCallControl-Button {
        margin-top: 16px;
    }
}
