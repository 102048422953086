.StreamsScreen {
    display: flex;
    justify-content: center;

    padding: 30px 0;
}

.StreamsScreen-Main {
    box-sizing: border-box;
    width: 100%;
    max-width: 960px;
    padding: 0 16px;
}

.StreamsScreen-PopularCurrentStreams .Streams {
    margin: 0 -15px;
}

.StreamsScreen-Stub {
    margin-top: 32px;
}

@media screen and (max-width: 767px) {
    .StreamsScreen-PopularCurrentStreams .Streams {
        margin: 0;
    }
}
