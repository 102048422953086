.CreateCallScreen-Content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    max-width: 560px;
}

.CreateCallScreen-Templates {
    min-width: 300px;
    margin-left: 100px;
}

.CreateCallScreen-TemplatesTitle {
    margin-top: 12px;
    padding-left: 23px;

    font-size: 18px;
    font-weight: bold;
}

.CreateCallScreen-TemplatesList {
    max-width: 300px;
    margin-top: 6px;
}

.CreateCallScreen-TemplatesList.TemplateList_loading {
    display: flex;
    justify-content: center;
    align-items: center;
}

.CreateCallScreen-TemplatesList .Template {
    padding-top: 20px;
    padding-bottom: 20px;
}

.CreateCallScreen-EventDescription {
    margin-top: 16px;
    margin-bottom: 8px;
}
