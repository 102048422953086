.InviteCallLink .InviteCallLink-Title {
    font-size: 18px;
    font-weight: bold;
}

.InviteCallLink .InviteCallLink-InviteLinkHint {
    display: inline-block;

    margin-left: 8px;
}

.InviteCallLink .InviteCallLink-Content {
    margin-top: 8px;
}

.InviteCallLink .InviteCallLink-InviteLinkButton {
    margin-left: 8px;
}
