.NotFoundScreen {
    display: flex;
    justify-content: center;

    padding-top: 42px;
}

.NotFoundScreen .Stub {
    max-width: 400px;
}
