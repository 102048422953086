.StreamScreen-RateInformer {
    position: fixed;
    right: 48px;
    bottom: 48px;

    max-width: 100%;
}

.StreamScreen-RateInformerTitle,
.StreamScreen-RateInformer {
    font-size: 18px;
    font-weight: 500;
}

.StreamScreen-RateInformerRating {
    margin-top: 16px;
}

.StreamScreen-RateInformerMessage {
    margin-top: 16px;
}

.StreamScreen-RateInformer .MessageBox-TextWrap {
    position: relative;
}

.StreamScreen-RateInformer .MessageBox-TextRight {
    position: absolute;
    top: 0;
    right: 0;
}

.StreamScreen-RateInformer .RatingDynamicStateless-Star {
    background-image: url('./assets/rates.svg');
}

.StreamScreen-RateInformer .Textarea-Box {
    border: none;
}

@media screen and (max-width: 500px) {
    .StreamScreen-RateInformer.MessageBox_size_l.MessageBox_layout_functional .MessageBox-Backdrop,
    .StreamScreen-RateInformer.MessageBox_size_l.MessageBox_layout_functional .MessageBox-Content,
    .StreamScreen-RateInformer.MessageBox_size_l.MessageBox_layout_functional .MessageBox-Buttons {
        width: 100%;
        min-width: unset;
        max-width: none;
    }

    .StreamScreen-RateInformer {
        right: 20px;
        left: 20px;
    }
}
