.Unit {
    box-sizing: border-box;
    padding: 1rem 2rem;

    background: #fff;
    box-shadow: 0 0 30px rgba(0, 0, 0, .1);
}

.Unit + .Unit {
    margin-top: 2rem;
}
