.CreateCallForm-PrivateStream {
    display: flex;

    margin-top: 24px;
}

.CreateCallForm-PrivateStreamLabel {
    flex-shrink: 0;

    width: 180px;
}

.CreateCallForm-PrivateStreamLabelTitle {
    font-size: 15px;
    font-weight: bold;
}

.CreateCallForm-PrivateStreamLabelSubTitle {
    margin-top: 4px;

    font-size: 11px;

    color: #999;
}

.CreateCallForm-PrivateStreamControl {
    margin-left: 54px;
}
