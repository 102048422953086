.TableCell_type_historyActions {
    justify-content: flex-end;
}

.TableCell_type_historyActions .TableCell-Button {
    width: 32px;
}

.TableCell_type_historyActions .TableCell-Button:not(:last-child) {
    margin: 0 12px 0 0;
}

@media screen and (max-width: 767px) {
    .TableCell_type_historyActions {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .TableCell_type_historyActions .TableCell-Button {
        width: 100%;
    }

    .TableCell_type_historyActions .TableCell-Button:not(:last-child) {
        margin: 0 0 12px;
    }
}
