.TableCell_type_activeCallParameters {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    line-height: 24px;
}

.TableCell_type_activeCallParameters .TableCell-ActiveCallParameters {
    display: flex;

    margin-top: 2px;

    font-size: 13px;
    cursor: default;
}

.TableCell_type_activeCallParameters .TableCell-ActiveCallParameter:not(:last-child) {
    margin-right: 16px;
}

.TableCell_type_activeCallParameters .TableCell-ActiveCallParameter_type_recorded {
    opacity: .3;
}

.TableCell-ActiveCallParameter_type_camera .Icon_type_camera {
    background-size: 26px;
}

.TableCell-ActiveCallParameter_type_camera {
    display: flex;
    align-items: center;

    margin-right: 16px;

    cursor: pointer;

    opacity: .3;
}

.TableCell-ActiveCallParameter_type_camera:hover {
    opacity: 1;
}

.TableCell_type_activeCallParameters .TableCell-CallName {
    overflow: hidden;

    max-width: 100%;

    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.TableCell_type_activeCallParameters .TableCell-StreamButtonLink .Button2-Text {
    margin: 0;
}

@media screen {
    .TableCell_type_activeCallParameters .TableCell-ActiveCallParameters {
        flex-wrap: wrap;
    }
}
