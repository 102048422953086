:root {
    --gray: #ccc;
    --gray-light: #f2f2f2;
}

body {
    margin: 0;
    padding: 0;

    font-family: Arial, sans-serif;
}

table {
    width: 100%;

    border-collapse: collapse;
}

table th,
table td {
    padding: .5rem .83rem;
}

table th {
    text-align: left;

    border: 1px solid var(--gray);
    background: var(--gray-light);
}

@media screen and (max-width: 767px) {
    .title {
        margin-left: 20px;
    }

    .Body-Modal_opened {
        overflow-y: hidden;

        height: 100vh;
    }
}
