.PermanentLinkScreen_error {
    display: flex;
    justify-content: center;

    padding-top: 42px;
}

.PermanentLinkScreen_error .PermanentLinkScreen-Stub {
    max-width: 400px;
}
