.InviteCallLinkScreen-CallInfo {
    padding: 0 16px;

    text-align: center;
}

.InviteCallLinkScreen-CallInfo .InviteCallLinkScreen-Annotation {
    max-width: 500px;
    margin: 32px auto 0;

    font-size: 16px;
    line-height: 22px;
}

.InviteCallLinkScreen-CallInfo .InviteCallLinkScreen-CallDate {
    margin-top: 16px;

    font-size: 22px;
    font-weight: bold;
}

.InviteCallLinkScreen-CallInfo .InviteCallLinkScreen-CallDate::first-letter {
    text-transform: uppercase;
}
