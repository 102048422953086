.ParticipantsList_suggest .Suggest .react-autosuggest__input {
    border-radius: 4px;
}

.ParticipantsList_suggest .Suggest .react-autosuggest__suggestions-container {
    border: none;
    border-radius: 0;
    background-color: #fff;
}

.ParticipantsList_suggest .ParticipantsList-Header {
    margin-top: 16px;
}

.ParticipantsList_suggest .ParticipantsList-SuggestButtons {
    display: flex;
    flex-wrap: wrap;

    margin-top: 16px;
}

.ParticipantsList_suggest .ParticipantsList-AddEventAttendeesButton {
    margin-left: auto;
}

.ParticipantsList_suggest .ParticipantsList-AddMeButton {
    margin-right: auto;
}

.ParticipantsList_suggest .ParticipantsList-AddMeButton + .ParticipantsList-AddEventAttendeesButton {
    margin-left: unset;
}
