.CreateCallScreen_loading {
    align-items: center;

    height: calc(100vh - 186px);
    padding: 0;
}

.CreateCallScreen-Spinner {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
}
