.InviteCallLinkScreen-CallInfo_type_authorized .InviteCallLinkScreen-EventLink {
    max-width: 700px;

    font-size: 32px;
    font-weight: bold;
}

.InviteCallLinkScreen-CallInfo_type_authorized .InviteCallLinkScreen-Icon {
    width: 36px;

    background-size: 28px;
}

.InviteCallLinkScreen-CallInfo_type_authorized .InviteCallLinkScreen-CreateCallButton {
    margin-top: 32px;
}
