.ConfirmationModal-Content {
    padding: 1rem 2rem;
}

.ConfirmationModal-Buttons {
    display: flex;

    padding: 1rem 0 0;
}

.ConfirmationModal-ConfirmButton {
    margin: 0 1rem 0 0;
}

@media screen and (max-width: 720px) {
    .ConfirmationModal-Buttons {
        flex-direction: column;
    }

    .ConfirmationModal-ConfirmButton {
        margin: 1rem 0;
    }

    .ConfirmationModal-Buttons .button2,
    .ConfirmationModal-Buttons .Button2 {
        width: 100%;
    }
}
